import Home from "./pages/Home"
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Saas from "./pages/Saas";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Marketplace from "./pages/Marketplace";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import config from "./config";

const Root = ({ children }) => {
  return <Outlet>{children}</Outlet>;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: {
      info: [
        { path: "", element: <Home /> },
        { path: "terms", element: <Terms /> },
        { path: "privacy", element: <Privacy /> },
        { path: "saas", element: <Saas /> },
      ],
      market: [{ path: "", element: <Marketplace /> }],
    }[config.domain],
  },
]);

function App() {
  return (
    <div id="remaster-body" className="App antialiased">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="colored"
      />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
